import React from "react";
import Landing from "../landing/Landing";
import Technologies from "../technologies/Technologies";
import CurrentProjects from "../current_projects/CurrentProjects";
import Experience from "../experience/Experience";
import Contact from "../contact/Contact";
const Content = () => {

    return (
        <div className="flex flex-col min-h-screen">
            <Landing/>
            <Technologies/>
            <CurrentProjects/>
            <Experience/>
            <Contact/>
            <footer className="flex w-full justify-center items-center pb-6 space-x-5">
                <a href="/#/legalnotice" className="text-white font-semibold">Legal Notice</a>
                <a href="/#/privacy" className="text-white font-semibold">Privacy Policy</a>
            </footer>
        </div>

    );
};

export default Content;