import React from "react";

const CurrentProjects = () => {
    return (
        <section className="flex flex-col w-full px-4 py-8 lg:px-14 lg:py-16">
            <div className="text-3xl md:text-4xl lg:text-5xl text-gray-300/75 pl-4 lg:pl-20 pt-10">
                But what am I developing
            </div>
            <div className="text-3xl md:text-4xl lg:text-5xl text-white font-bold pl-4 lg:pl-20">
                CURRENTLY?
            </div>
            <div className="flex flex-col lg:flex-row w-full justify-center lg:justify-around items-center pt-16 lg:pt-12 space-y-8 lg:space-y-0">
                <div className="flex flex-col p-4 h-72 lg:h-96 justify-start text-center rounded-2xl w-9/12 lg:w-1/3">
                    <a href={"https://pantry-master.com"} // Change to /pantrymaster
                       className="flex flex-col w-full text-center lg:text-left px-4 lg:px-0 space-y-8">
                        <div
                            className="flex flex-row w-full justify-center text-center text-white font-bold text-2xl md:text-3xl lg:text-3xl">
                            1. Pantry Master
                        </div>
                        <div
                            className="flex flex-row w-full justify-center text-center text-white font-medium text-base md:text-lg lg:text-xl">
                            An AI-powered mobile household management tool for Android and iOS to keep important steps
                            as
                            simple as possible.
                        </div>
                    </a>
                </div>
                <div
                    className="flex flex-col p-4 h-72 lg:h-96 justify-start text-center rounded-2xl w-9/12 lg:w-1/3 space-y-8">
                    <div className="text-white font-bold text-2xl md:text-3xl lg:text-3xl">
                        2. Atmosphereum
                    </div>
                    <div className="text-white font-medium text-base md:text-lg lg:text-xl">
                        A Web3 application where you can reduce your carbon footprint and get rewarded with our own
                        atmosm coins. Public information & presentation coming soon.
                    </div>
                </div>
                <div
                    className="flex flex-col p-4 h-72 lg:h-96 justify-start text-center rounded-2xl w-9/12 lg:w-1/3 space-y-8">
                    <div className="text-white font-bold text-2xl md:text-3xl lg:text-3xl">
                        3. Quick List
                    </div>
                    <div className="text-white font-medium text-base md:text-lg lg:text-xl">
                        Quick List is designed for simplicity and efficiency. It allows users to manage their shopping quickly without unnecessary features. With its modern design, it ensures a clear and intuitive user experience.
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CurrentProjects;